<template>
  <div class="order pd60">
    <div class="order-top">
      <div class="search-box">
        <div class=""><a-icon type="left" @click="$router.go('-1')" /></div>
        <div class="search-title">
          <span v-show="!search">我的事项</span>
          <div class="search-input" v-show="search">
            <input
              v-model="searchTitle"
              placeholder="请输入关键字"
              @focus="isshowClose = true"
            />
            <span @click="clearSearch" v-show="isshowClose" class="closeIcon">
              <a-icon
                type="close-circle"
                theme="filled"
                style="color: #bdbdbd"
              />
            </span>
            <span
              class="iconfont icon-hps-appsousuo fz18 ft-org ml5"
              @click="goSearch"
            ></span>
          </div>
        </div>
        <div>
          <span v-show="search" @click="search = false">取消</span>
          <span
            v-show="!search"
            class="iconfont icon-hps-appsousuo fz24"
            @click="search = true"
          ></span>
        </div>
      </div>
      <div class="menu-tabs border-bottom">
        <a-tabs
          :default-active-key="nowTab"
          :tabBarGutter="20"
          @change="tabChange"
        >
          <a-tab-pane key="2"
            ><span slot="tab"
              ><a-badge :count="forHandleNumber">待处理</a-badge></span
            ></a-tab-pane
          >
          <a-tab-pane key="3"
            ><span slot="tab"
              ><a-badge :count="0">已处理</a-badge></span
            ></a-tab-pane
          >
          <a-tab-pane key="1">
            <span slot="tab"
              ><a-badge :count="0">已提交</a-badge></span
            ></a-tab-pane
          >
          <a-tab-pane key="4"
            ><span slot="tab"
              ><a-badge :count="0">待提交</a-badge></span
            ></a-tab-pane
          >
        </a-tabs>
      </div>
    </div>
    <div
      v-infinite-scroll="handleInfiniteOnLoad"
      class="demo-infinite-container"
      :infinite-scroll-disabled="busy"
      :infinite-scroll-distance="10"
    >
      <ListA
        :data="data"
        v-show="nowTab === '2'"
        :isWait="true"
        @go="setBusy"
      />
      <ListA :data="data" v-show="nowTab === '3'" @go="setBusy" />
      <ListB :data="data" v-show="nowTab === '1'" @go="setBusy" />
      <ListB :data="data" v-show="nowTab === '4'" :draft="true" @go="setBusy" />
    </div>
    <Footer />
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import { Tabs, Badge, Icon } from "ant-design-vue";
import Footer from "@/components/footer/index";
import ListA from "@/components/order/listA";
import ListB from "@/components/order/listB";
import {
  getSubList,
  getHandleList,
  getWaitList,
  getDraftList,
  getOrderItemsNumber,
} from "@/utils/api";
export default {
  components: {
    Footer,
    ListB,
    ListA,
    ATabs: Tabs,
    AIcon: Icon,
    ATabPane: Tabs.TabPane,
    ABadge: Badge,
  },
  directives: { infiniteScroll },
  data() {
    return {
      nowTab: this.$route.query.index || "1",
      data: [],
      forHandleNumber: 0,
      forSubmitNumber: 0,
      handledNumber: 0,
      submitNumber: 0,
      loading: false,
      busy: false,
      currentPage: 1,
      totalPage: 1,
      subList: [],
      subCurrentPage: 1,
      subTotalPage: 1,
      waitList: [],
      waitCurrentPage: 1,
      waitTotalPage: 1,
      handleList: [],
      handleCurrentPage: 1,
      handleTotalPage: 1,
      draftList: [],
      draftCurrentPage: 1,
      draftTotalPage: 1,
      search: false,
      searchTitle: "",
      isSearch: false,
      isshowClose: false,
    };
  },
  created() {
    // this.getData(0);
    this.getOrderItemsNumber();
  },
  methods: {
    clearSearch() {
      this.searchTitle = "";
      this.goSearch();
      this.isshowClose = false;
    },
    goSearch() {
      this.isSearch = true;
      this.getData(0);
    },
    setBusy() {
      this.busy = true;
    },
    async getOrderItemsNumber() {
      const { data } = await getOrderItemsNumber();
      this.forHandleNumber = data.forHandleNumber;
      this.forSubmitNumber = data.forSubmitNumber;
      this.handledNumber = data.handledNumber;
      this.submitNumber = data.submitNumber;
    },
    getData(num) {
      switch (this.nowTab) {
        case "1":
          this.currentPage = this.subCurrentPage + num;
          this.getSubList({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
        case "2":
          this.currentPage = this.waitCurrentPage + num;
          this.getWaitList({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
        case "3":
          this.currentPage = this.handleCurrentPage + num;
          this.getHandleList({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
        case "4":
          this.currentPage = this.draftCurrentPage + num;
          this.getDraftList({
            page: this.currentPage,
            limit: 10,
            title: this.searchTitle,
          });
          break;
      }
    },
    handleInfiniteOnLoad() {
      if (this.busy) return;
      this.loading = true;
      this.busy = true;
      this.tabChangeOn = false;
      if (this.data.length === 0) {
        this.getData(0);
      } else if (this.currentPage < this.totalPage) {
        this.getData(1);
      }
    },
    tabChange(key) {
      this.loading = true;
      this.nowTab = key;
      this.tabChangeOn = true;
      this.busy = true;
      this.$router.replace({
        query: {
          index: key,
        },
      });
      // this.data = [];
      this.getData(0);
    },
    //查询已提交
    async getSubList(params) {
      if (this.isSearch) {
        this.subList = [];
        this.isSearch = false;
      }
      if (this.subList.length > 0 && this.tabChangeOn) {
        this.data = this.subList;
        return;
      }
      const { data, currentPage, totalPage } = await getSubList(params);
      this.subCurrentPage = currentPage;
      this.subTotalPage = totalPage;
      for (let i = 0; i < data.length; i++) {
        this.subList.push(data[i]);
      }
      this.data = this.subList;
      this.totalPage = this.subTotalPage;
      this.loading = false;
      this.busy = false;
    },
    //查询已处理
    async getHandleList(params) {
      if (this.isSearch) {
        this.handleList = [];
        this.isSearch = false;
      }
      if (this.handleList.length > 0 && this.tabChangeOn) {
        this.data = this.handleList;
        return;
      }
      const { data, currentPage, totalPage } = await getHandleList(params);
      this.handleCurrentPage = currentPage;
      this.handleTotalPage = totalPage;
      for (let i = 0; i < data.length; i++) {
        this.handleList.push(data[i]);
      }
      this.data = this.handleList;
      this.totalPage = this.handleTotalPage;
      this.loading = false;
      this.busy = false;
    },
    //查询待处理
    async getWaitList(params) {
      if (this.isSearch) {
        this.waitList = [];
        this.isSearch = false;
      }
      if (this.waitList.length > 0 && this.tabChangeOn) {
        this.data = this.waitList;
        return;
      }

      const { data, currentPage, totalPage, count } = await getWaitList(params);
      this.forHandleNumber = count;
      this.waitCurrentPage = currentPage;
      this.waitTotalPage = totalPage;
      this.totalPage = this.waitTotalPage;
      for (let i = 0; i < data.length; i++) {
        this.waitList.push(data[i]);
      }
      this.data = this.waitList;
      this.loading = false;
      this.busy = false;
    },
    //查询待提交
    async getDraftList(params) {
      if (this.isSearch) {
        this.draftList = [];
        this.isSearch = false;
      }
      if (this.draftList.length > 0 && this.tabChangeOn) {
        this.data = this.draftList;
        return;
      }

      const { data, currentPage, totalPage } = await getDraftList(params);
      this.draftCurrentPage = currentPage;
      this.draftTotalPage = totalPage;
      for (let i = 0; i < data.length; i++) {
        this.draftList.push(data[i]);
      }
      this.data = this.draftList;
      this.totalPage = this.draftTotalPage;
      this.loading = false;
      this.busy = false;
    },
  },
};
</script>
<style lang="less">
.order {
  .demo-infinite-container {
    margin-top: 9.8rem;
  }
  .avatar img {
    // width: 2.3rem;
    // height: 2.3rem;
    border-radius: 50%;
  }
  .order-list {
    h5 {
      color: #121212;
    }
  }
  .order-top {
    position: fixed;
    top: 0;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 75rem;
  }
  .menu-tabs {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 100%;
  }
  .search-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 4.4rem;
    background-color: #f39800;
    width: 100%;
    div {
      // width: 30%;
      text-align: center;
      color: #fff;
      font-size: 1.8rem;
    }
    .search-title {
      width: 70%;
      text-align: center;
    }
    .search-input {
      height: 3.3rem;
      background: #ffffff;
      border-radius: 1.7rem;
      display: flex;
      align-items: center;
      padding-left: 10px;
      position: relative;
      input {
        margin-left: 5px;
        border: 0;
        outline: 0;
        height: 3.3rem;
        font-size: 1.2rem;
        width: 78%;
        color: #121212;
      }
      .closeIcon {
        position: absolute;
        right: 3.5rem;
      }
      .iconfont {
        position: absolute;
        right: 1rem;
        z-index: 10;
      }
    }
  }
}
</style>