<template>
  <a-list :data-source="data" item-layout="vertical">
    <a-list-item
      slot="renderItem"
      slot-scope="item"
      class="bg-block mt10 nobd pdlr15 order-list"
    >
      <a class="block" @click="sendTo(item.id)">
        <h5 class="fz14 overflowEllipsis">{{ item.title }}</h5>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">编号</span>
          <span class="">{{ item.orderId }}</span>
        </div>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">所属分类</span>
          <span class=""
            >{{ item.orderClassficationName }}/{{
              item.matterClassficationName
            }}</span
          >
        </div>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">紧急性</span>
          <span class="">{{ item.matterPropertyName }}</span>
        </div>
        <div class="flex-between fz12 mt5 ft-light" v-if="draft">
          <span class="ft-grey">操作时间</span>
          <span class="">{{ formatDate(item.updateTime) }}</span>
        </div>
        <div class="flex-between fz12 mt5 ft-light" v-else>
          <span class="ft-grey">上报时间</span>
          <span class="">{{ formatDate(item.reportTime) }}</span>
        </div>
        <div class="list-block flex-between mt10 border-top pt10" v-if="!draft">
          <div>
            <a-avatar class="avatar" slot="avatar" :src="item.nextUserAvatar" />
            <span class="ml10 fz12">{{ item.nextUserName }}</span>
          </div>
          <span
            class="status"
            :class="{
              'status-wait':
                item.status === 'forHandle' ||
                item.status === 'forSubmit' ||
                item.status === 'forAcceptance',
              'status-accpeted': item.status === 'completed',
              'status-back': item.status === 'withdrawn',
              'status-fail': item.status === 'handledFailure',
            }"
            >{{ status[item.status] }}</span
          >
        </div>
        <div
          class="list-block flex-between mt10 border-top pt10 flex-center"
          v-else
        >
          <span class="status status-back">{{ item.statusName }}</span>
          <span class="ft-grey"
            ><a-icon type="right" style="color: #bdbdbd"
          /></span>
        </div>
      </a>
    </a-list-item>
    <div v-if="loading && !busy" class="demo-loading-container">
      <a-spin />
    </div>
  </a-list>
</template>
<script>
import { List, Spin, Avatar, Icon } from "ant-design-vue";
export default {
  name: "ListA",
  components: {
    AAvatar: Avatar,
    AList: List,
    ASpin: Spin,
    AIcon: Icon,
    AListItem: List.Item,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: {
        forHandle: "正在处理",
        forAcceptance: "验收中",
        completed: "已完成",
        handledFailure: "暂不受理",
        withdrawn: "已撤销",
      },
    };
  },
  methods: {
    sendTo(id) {
      this.$emit("go");
      if (!this.draft) {
        this.$router.push(`/detail?id=${id}`);
      } else {
        this.$router.push(`/report?id=${id}`);
      }
    },
  },
};
</script>