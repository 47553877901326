var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-list',{attrs:{"data-source":_vm.data,"item-layout":"vertical"},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{staticClass:"bg-block mt10 nobd pdlr15 order-list"},[_c('a',{staticClass:"block",on:{"click":function($event){return _vm.sendTo(item.id)}}},[_c('h5',{staticClass:"fz14 overflowEllipsis"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"flex-between fz12 mt5 ft-light"},[_c('span',{staticClass:"ft-grey"},[_vm._v("编号")]),_c('span',{},[_vm._v(_vm._s(item.orderId))])]),_c('div',{staticClass:"flex-between fz12 mt5 ft-light"},[_c('span',{staticClass:"ft-grey"},[_vm._v("所属分类")]),_c('span',{},[_vm._v(_vm._s(item.orderClassficationName)+"/"+_vm._s(item.matterClassficationName))])]),_c('div',{staticClass:"flex-between fz12 mt5 ft-light"},[_c('span',{staticClass:"ft-grey"},[_vm._v("紧急性")]),_c('span',{},[_vm._v(_vm._s(item.matterPropertyName))])]),(_vm.draft)?_c('div',{staticClass:"flex-between fz12 mt5 ft-light"},[_c('span',{staticClass:"ft-grey"},[_vm._v("操作时间")]),_c('span',{},[_vm._v(_vm._s(_vm.formatDate(item.updateTime)))])]):_c('div',{staticClass:"flex-between fz12 mt5 ft-light"},[_c('span',{staticClass:"ft-grey"},[_vm._v("上报时间")]),_c('span',{},[_vm._v(_vm._s(_vm.formatDate(item.reportTime)))])]),(!_vm.draft)?_c('div',{staticClass:"list-block flex-between mt10 border-top pt10"},[_c('div',[_c('a-avatar',{staticClass:"avatar",attrs:{"slot":"avatar","src":item.nextUserAvatar},slot:"avatar"}),_c('span',{staticClass:"ml10 fz12"},[_vm._v(_vm._s(item.nextUserName))])],1),_c('span',{staticClass:"status",class:{
            'status-wait':
              item.status === 'forHandle' ||
              item.status === 'forSubmit' ||
              item.status === 'forAcceptance',
            'status-accpeted': item.status === 'completed',
            'status-back': item.status === 'withdrawn',
            'status-fail': item.status === 'handledFailure',
          }},[_vm._v(_vm._s(_vm.status[item.status]))])]):_c('div',{staticClass:"list-block flex-between mt10 border-top pt10 flex-center"},[_c('span',{staticClass:"status status-back"},[_vm._v(_vm._s(item.statusName))]),_c('span',{staticClass:"ft-grey"},[_c('a-icon',{staticStyle:{"color":"#bdbdbd"},attrs:{"type":"right"}})],1)])])])}}])},[(_vm.loading && !_vm.busy)?_c('div',{staticClass:"demo-loading-container"},[_c('a-spin')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }