<template>
  <a-list :data-source="data" item-layout="vertical">
    <a-list-item
      slot="renderItem"
      slot-scope="item"
      class="bg-block mt10 nobd pdlr15 order-list"
    >
      <a class="block" @click="setBusy(item.id)">
        <div class="list-block flex-between">
          <div>
            <a-avatar class="avatar" slot="avatar" :src="item.reporterAvatar" />
            <span class="ml10">{{ item.createName }}的上报</span>
          </div>
          <span class="status status-back" v-if="!isWait">{{
            item.lastOperateName
          }}</span>
          <span
            class="status"
            :class="{
              'status-wait':
                item.status === 'forHandle' ||
                item.status === 'forSubmit' ||
                item.status === 'forAcceptance',
              'status-accpeted': item.status === 'completed',
              'status-back': item.status === 'withdrawn',
              'status-fail': item.status === 'handledFailure',
            }"
            v-else
            >{{ item.statusName }}</span
          >
        </div>
        <h5 class="mt15 fz12 overflowEllipsis">{{ item.title }}</h5>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">编号</span>
          <span class="">{{ item.orderId }}</span>
        </div>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">所属分类</span>
          <span class=""
            >{{ item.orderClassficationName }}/{{
              item.matterClassficationName
            }}</span
          >
        </div>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">紧急性</span>
          <span class="">{{ item.matterPropertyName }}</span>
        </div>
        <div class="flex-between fz12 mt5 ft-light">
          <span class="ft-grey">上报时间</span>
          <span class="">{{ formatDate(item.reportTime) }}</span>
        </div>
      </a>
    </a-list-item>
    <div v-if="loading && !busy" class="demo-loading-container">
      <a-spin />
    </div>
  </a-list>
</template>
<script>
import { List, Spin, Avatar } from "ant-design-vue";
export default {
  name: "ListA",
  components: {
    AAvatar: Avatar,
    AList: List,
    ASpin: Spin,
    AListItem: List.Item,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isWait: {
      type: Boolean,
      default: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    setBusy(id) {
      this.$emit("go");
      this.$router.push(`/detail?id=${id}`);
    },
  },
};
</script>